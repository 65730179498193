import React, { useState } from 'react';

import { ArrowDown, ArrowUp } from '@pulse-web-ui/icons';

import { OrderItemAccordionType } from '@src/types';
import { addTestAttribute } from '@src/utils';

import {
  AccordionArrowIcon,
  AccordionToggle,
  OrderItemTitle,
  OrderItemValue,
  OrderItemWrapper,
} from './components.styles';

export const OrderAccordionItem = ({
  title,
  value,
  accordionTitle,
  testId = 'orderAccordionItem',
}: OrderItemAccordionType) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <OrderItemWrapper>
      <AccordionToggle
        onClick={() => setIsOpen(!isOpen)}
        {...addTestAttribute(`${testId}.toggle`)}
      >
        <OrderItemWrapper>
          <OrderItemTitle {...addTestAttribute(`${testId}.title`)}>
            {title}
          </OrderItemTitle>
          <OrderItemValue>{accordionTitle}</OrderItemValue>
          <AccordionArrowIcon>
            {isOpen ? <ArrowUp width={20} /> : <ArrowDown width={20} />}
          </AccordionArrowIcon>
        </OrderItemWrapper>
      </AccordionToggle>
      {isOpen && <div {...addTestAttribute(`${testId}.value`)}>{value}</div>}
    </OrderItemWrapper>
  );
};
