import { css, styled, typography } from '@pulse-web-ui/theme';

export const Resend = styled.div`
  text-align: center;
  ${typography.regular12};
`;

export const ResentText = styled.div<{
  disabled?: boolean;
  seconds: number;
}>`
  width: 100%;
  ${typography.regular16};
  color: ${({ theme }) => theme.colors.text.interactive};
  text-align: left;

  cursor: ${({ seconds }) => (seconds > 0 ? 'auto' : 'pointer')};
  color: ${({ theme }) => theme.colors.text.secondary};
  ${({ seconds }) =>
    !(seconds > 0) &&
    css`
      color: ${({ theme }) => theme.colors.text.interactive};
    `}
`;
