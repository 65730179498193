/* eslint-disable indent */
import { css, media, styled } from '@pulse-web-ui/theme';

export const StyledIframe = styled.iframe<{ isPrivateOffice?: boolean }>`
  width: 100%;
  height: calc(100% - 48px);
  position: absolute;
  left: 0;
  top: 48px;

  ${media.desktop} {
    ${({ isPrivateOffice }) =>
      isPrivateOffice
        ? css`
            height: calc(100% - 78px);
            top: 78px;
          `
        : css`
            height: 100%;
            top: 0;
          `}
  }
`;
