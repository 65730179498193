import parse from 'html-react-parser';

import { useTheme } from '@pulse-web-ui/theme';

export const ScoreErrorIcon = () => {
  const theme: any = useTheme();
  const themeIcon = theme?.['colored-icons']?.['score-error-icon'];

  return themeIcon ? (
    <>{parse(themeIcon)}</>
  ) : (
    <svg
      width="80"
      height="81"
      viewBox="0 0 80 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 40.6631C3 20.2285 19.5655 3.66308 40 3.66308C60.4345 3.66308 77 20.2285 77 40.6631C77 61.0976 60.4345 77.6631 40 77.6631C19.5655 77.6631 3.00001 61.0976 3 40.6631Z"
        fill="#E4E7EE"
        stroke="#21145A"
        strokeWidth="2"
      />
      <path
        d="M46.5401 40.8111L45.833 41.5182L46.5401 42.2253L58.9691 54.6543C60.3437 56.0289 60.3437 58.2576 58.9691 59.6321C57.5945 61.0067 55.3658 61.0067 53.9912 59.6321L41.5622 47.2031L40.8551 46.496L40.148 47.2031L28.0741 59.277C26.5034 60.8477 23.9568 60.8477 22.3861 59.277C20.8153 57.7063 20.8153 55.1597 22.3861 53.589L34.4599 41.5151L35.167 40.808L34.4599 40.1009L22.0309 27.6719C20.6564 26.2973 20.6564 24.0686 22.0309 22.694C23.4055 21.3194 25.6342 21.3194 27.0088 22.694L39.4378 35.123L40.1449 35.8301L40.852 35.123L52.9259 23.0491C54.4966 21.4784 57.0432 21.4784 58.6139 23.0491C60.1847 24.6199 60.1847 27.1665 58.6139 28.7372L46.5401 40.8111Z"
        fill="#F3566A"
        stroke="#21145A"
        strokeWidth="2"
      />
    </svg>
  );
};
