import { css, media, mixins, styled, typography } from '@pulse-web-ui/theme';

type OrderItemTitleProps = {
  isColsIdentical?: boolean;
};

type OrderItemListProps = {
  marginTop?: number;
  marginBottom?: number;
};

export const AccordionToggle = styled.div`
  cursor: pointer;
  transition: background 0.2s ease-in-out;
  position: relative;
  > div {
    padding-bottom: 0;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.neutral[10]};
  }
`;

export const AccordionArrowIcon = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
`;

export const OrderItemWrapper = styled.div<{ showSeparator?: boolean }>`
  ${mixins.flex({
    horizontal: false,
    gap: 8,
    justifyContent: 'flex-start',
  })}
  margin-bottom: 17px;
  padding-bottom: 9px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[30]};
  box-sizing: border-box;

  &:last-child {
    margin-bottom: 0;
  }

  ${({ showSeparator }) =>
    !showSeparator &&
    css`
      &:last-child {
        border-bottom: 0;
      }
    `}
`;

export const OrderCheckoutItemWrapper = styled.div`
  ${mixins.flex({
    horizontal: false,
    gap: 8,
    justifyContent: 'flex-start',
  })}
  margin-bottom: 13px;
  padding-bottom: 9px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[30]};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const OrderCheckoutAggrWrapper = styled.div`
  margin: 32px 0;
`;

export const OrderBlockItemWrapper = styled.div`
  ${mixins.flex({
    horizontal: false,
    gap: 0,
    justifyContent: 'flex-start',
  })}
  padding-top: 32px;
`;

export const OrderContentWrapper = styled.div`
  width: 100%;
  margin-bottom: 32px;
`;

export const OrderPageTitleWrapper = styled.h2`
  ${typography.medium24}
  color: ${({ theme }) => theme.colors.text.primary};
  margin-bottom: 24px;
  padding-top: 32px;
  letter-spacing: -0.02em;
  font-weight: 600;
`;

export const OrderItemTitle = styled.div<OrderItemTitleProps>`
  ${typography.regular14};
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const OrderCheckoutItemTitle = styled.div<OrderItemTitleProps>`
  ${typography.regular14};
  color: ${({ theme }) => theme.colors.text.secondary};

  ${media.desktop} {
    flex-basis: ${(props) => (props.isColsIdentical ? '50%' : '200px')};
    flex-shrink: 0;
  }
`;

export const OrderCheckoutTitleWrapper = styled.div`
  margin-bottom: 16px;
`;

export const OrderBlockItemTitle = styled.div`
  ${mixins.flex({
    horizontal: true,
    justifyContent: 'space-between',
  })}
  margin-bottom: 16px;
`;

export const OrderBlockItemTitleButton = styled.button`
  color: ${({ theme }) => theme.colors.text.interactive};
  text-decoration: none;
  border: none;
  outline: none;
  user-select: none;
  cursor: pointer;
  background: none;
  padding: 0;
  overflow: hidden;
`;

export const OrderItemValueWrapper = styled.div``;

export const OrderItemValue = styled.div`
  ${typography.bold16};
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const OrderItemList = styled.div<OrderItemListProps>`
  ${typography.bold16};
  color: ${({ theme }) => theme.colors.text.primary};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : 11)}px;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : 0)}px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const OrderItemSubValue = styled.div`
  ${typography.regular14};
  color: ${({ theme }) => theme.colors.text.primary};
  margin-top: 6px;
`;
export type OrderItemStatusValueProps = {
  error: boolean;
};

export const OrderItemStatusValue = styled.div<OrderItemStatusValueProps>`
  ${typography.regular14}
  line-height: 20px;
  letter-spacing: 0.02em;
  margin-top: 8px;
  color: ${({ error, theme }) =>
    error ? theme.colors.text.error : theme.colors.text.success};
`;

export const DownloadItemWrapper = styled.div`
  ${mixins.flex({ horizontal: true, gap: 8, alignItems: 'center' })}
  margin-bottom: 13px;
  padding-bottom: 13px;
  color: ${({ theme }) => theme.colors.text.primary};

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }
`;

export const DownloadItemContent = styled.div`
  ${mixins.flex({
    inline: true,
    horizontal: true,
    gap: 8,
    alignItems: 'center',
  })}
  cursor: pointer;
`;

export const EmailWrapper = styled.div`
  margin-top: 32px;
`;

export const OrderBlockItemHead = styled.h2`
  ${typography.medium24}
  color: ${({ theme }) => theme.colors.text.primary};
  margin-bottom: 4px;
  letter-spacing: 0;
`;

export const SubText = styled.span`
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const SubTextLighter = styled.div`
  ${typography.regular14};
  color: ${({ theme }) => theme.colors.neutral[80]};
`;

export const DocumentsSubTitle = styled.div`
  ${typography.regular16}
  line-height: 24px;
  color: ${(props) => props.theme.colors.text.secondary};
  margin-bottom: 8px;

  ${media.desktop} {
    ${typography.regular18}
    line-height: 26px;
  }
`;

export const OrderCheckoutItemValue = styled.div`
  ${typography.medium16};
  color: ${({ theme }) => theme.colors.text.primary};
  line-height: 24px;

  ${media.desktop} {
    ${typography.medium18};
    line-height: 26px;
  }
`;

export const OrderCheckoutItemSubValue = styled.div`
  ${typography.regular14};
  color: ${({ theme }) => theme.colors.text.primary};
  margin-top: 4px;
  line-height: 20px;
`;
