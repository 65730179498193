/* eslint-disable indent */
import { Button } from '@pulse-web-ui/button';
import { Checkbox } from '@pulse-web-ui/checkbox';
import { media, mixins, styled, typography } from '@pulse-web-ui/theme';

import { CustomButtonProps } from '@src/components/form-elements/form-elements.types';

export const StyledVisibleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledDeleteButton = styled.div`
  ${typography.regular18}
  color:  ${({ theme }) => theme.colors.text.error};
  margin-right: 12px;
  cursor: pointer;
`;

export const InsurancePersonFormHead = styled.div`
  ${mixins.flex({
    alignItems: 'center',
    justifyContent: 'space-between',
    wrap: false,
  })}
  min-height: 28px;
  margin-bottom: 16px;

  ${media.desktop} {
    margin-bottom: 24px;
  }
`;

export const InsurancePersonGroup = styled.div`
  width: 100%;
`;

export const InsurancePersonFormContainer = styled.div`
  ${mixins.flex({
    horizontal: false,
    wrap: false,
    horizontalAlignment: 'flex-start',
    gap: 24,
  })}
  &:not(:last-child) {
    margin-bottom: 32px;
  }
`;

export const InsurancePersonFormTitleBox = styled.div`
  ${mixins.flex({
    alignItems: 'center',
    wrap: false,
    gap: 8,
  })}
`;

export const InsurancePersonFormTitle = styled.div`
  ${typography.medium18}
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const InsurancePersonFormSubTitle = styled.div`
  ${typography.regular14}
  color: ${({ theme }) => theme.colors.text.secondary};
  padding-top: 2px;
`;

export const InsurancePersonFormCheckbox = styled(Checkbox)`
  ${media.desktop} {
    order: 1;
  }
`;

export const InsurancePersonFormNumber = styled.div<{
  choosedPolicy?: string;
}>`
  ${typography.pulsSubhead4.regular}
  ${mixins.flex({
    alignItems: 'center',
    justifyContent: 'center',
  })}
  color:  ${({ theme }) => theme.colors.text.inverse};
  width: 20px;
  height: 20px;
  background: ${({ theme }) => theme.colors.surface.brand};

  border-radius: 50%;

  ${media.desktop} {
    width: 24px;
    height: 24px;
  }
`;

export const InsurancePersonFormButton = styled.button<CustomButtonProps>`
  ${typography.regular16}
  background: ${({ active, theme }) =>
    active ? theme.colors.surface.brand : 'none'};
  padding: 3px 11px 5px;
  cursor: pointer;
  user-select: none;
  outline: none;
  border: 1px solid
    ${({ active, theme }) =>
      active ? theme.colors.surface.brand : theme.colors.neutral[20]};
  border-radius: 29px;
  color: ${({ active, theme }) =>
    active ? theme.colors.text.inverse : theme.colors.text.secondary};
`;

export const StyledButton = styled.div`
  padding-top: 8px;
  paddng-bottom: 0;
  ${media.desktop} {
    padding: 8px 0;
  }
`;
