import { media, mixins, styled, typography } from '@pulse-web-ui/theme';

export const TotalSumWrapper = styled.div<{ isError: boolean }>`
  border: ${({ isError, theme }) =>
    isError
      ? `2px solid ${theme.colors.accent.error.normal}`
      : `2px solid ${theme.colors.neutral[30]}`};
  border-radius: ${({ theme }) => theme.common.radius};
  padding: 16px 24px 12px;
  box-sizing: border-box;
  width: 100%;
  background: ${({ isError, theme }) =>
    isError ? theme.colors.accent.error.faded : theme.colors.neutral[0]};

  ${mixins.flex({ horizontal: false })}
  ${media.desktop} {
    padding: 16px 24px;
    min-height: 114px;
  }
`;

export const TotalSumValue = styled.div`
  ${typography.bold22}
  color: ${({ theme }) => theme.colors.text.primary};
  white-space: nowrap;
  margin-bottom: 3px;
`;

export const TotalSumTitle = styled.div`
  ${typography.regular16}
  color: ${({ theme }) => theme.colors.text.primary};
  white-space: nowrap;
  margin-bottom: 3px;
`;

export const TotalSumSubtitle = styled.div<{ isError: boolean }>`
  ${typography.regular16}
  color: ${({ isError, theme }) =>
    isError ? theme.colors.text.error : theme.colors.text.secondary};
  letter-spacing: 0.02em;
`;
