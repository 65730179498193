import { Trans, useTranslation } from 'react-i18next';

import { Close, SystemCheck } from '@pulse-web-ui/icons';
import { useTheme } from '@pulse-web-ui/theme';

import { MitePolicyChoose } from '@src/constants/mite-choose';

export const useMiteChoose = () => {
  const { t } = useTranslation();
  const theme: any = useTheme();

  const HEALTH_CARE = t('MITE_FORM:labels.healthCare');
  const REHABILITATION = t('MITE_FORM:labels.rehabilitation');
  const COVER_AMOUNT = t('COMMON:labels.coverAmount');

  const MITE_CHOOSE = {
    [MitePolicyChoose.PERSON]: {
      id: 1,
      cardData: [
        {
          key: HEALTH_CARE,
          value: <SystemCheck width={28} color={theme.colors.icon.success} />,
        },
        {
          key: REHABILITATION,
          value: <Close width={25} color={theme.colors.icon.quaternary} />,
        },
        {
          key: <Trans>{t('MITE_FORM:labels.payoutOnBites')}</Trans>,
          value: <Close width={25} color={theme.colors.icon.quaternary} />,
        },
        {
          key: <Trans>{t('MITE_FORM:labels.medicationCosts')}</Trans>,
          value: t('COMMON:price.toRubles', { price: '10 000' }),
        },
        {
          key: COVER_AMOUNT,
          value: t('COMMON:price.million', { price: '1,5' }),
          isLast: true,
        },
      ],
    },
    [MitePolicyChoose.PERSON_PLUS]: {
      id: 2,
      cardData: [
        {
          key: HEALTH_CARE,
          value: <SystemCheck width={28} color={theme.colors.icon.success} />,
        },
        {
          key: REHABILITATION,
          value: <SystemCheck width={28} color={theme.colors.icon.success} />,
        },
        {
          key: <Trans>{t('MITE_FORM:labels.payoutOnBites')}</Trans>,
          value: t('COMMON:price.toMillion', { price: '1,5' }),
        },
        {
          key: <Trans>{t('MITE_FORM:labels.medicationCosts')}</Trans>,
          value: t('COMMON:price.toRubles', { price: '10 000' }),
        },
        {
          key: COVER_AMOUNT,
          value: t('COMMON:price.million', { price: '1,5' }),
          isLast: true,
        },
      ],
    },
    [MitePolicyChoose.RELATIVES]: {
      id: 3,
      cardData: [
        {
          key: HEALTH_CARE,
          value: <SystemCheck width={28} color={theme.colors.icon.success} />,
        },
        {
          key: REHABILITATION,
          value: <SystemCheck width={28} color={theme.colors.icon.success} />,
        },
        {
          key: <Trans>{t('MITE_FORM:labels.payoutOnBites')}</Trans>,
          value: t('COMMON:price.toMillion', { price: '1,5' }),
        },
        {
          key: <Trans>{t('MITE_FORM:labels.medicationCosts')}</Trans>,
          value: t('COMMON:price.toRubles', { price: '10 000' }),
        },
        {
          key: COVER_AMOUNT,
          value: t('COMMON:price.million', { price: '1,5' }),
          isLast: true,
        },
      ],
    },
  };

  return MITE_CHOOSE;
};
