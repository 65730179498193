import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@pulse-web-ui/button';

import { ScoreErrorIcon } from './score-error-icon';
import { StyledBox, StyledButtonBox, StyledText } from './score-error.styles';

const CLIENT_LANDING_URL = 'https://pulse.insure/';

interface ErrorPageProps {
  isObjectDataError?: boolean;
  isScrinning?: boolean;
  isAgentFlow?: boolean;
}

export const ScoreErrorContent: FC<ErrorPageProps> = ({
  isObjectDataError,
  isScrinning,
  isAgentFlow,
}) => {
  const { t } = useTranslation();
  const errorText = isScrinning
    ? t('SCORE_ERROR:errors.subscriptionIsNotAvailableForScrinning')
    : t('SCORE_ERROR:errors.subscriptionIsNotAvailable');
  const buttonLabel = isScrinning
    ? t('COMMON:buttons.understandably')
    : t('COMMON:buttons.goBackMainPage');

  const handleClick = () => {
    const globalWindow = window.top as Window;

    globalWindow.location.href = isAgentFlow
      ? window.envUrls.AGENT_URL
      : CLIENT_LANDING_URL;
  };

  const objectErrorText = t(
    'SCORE_ERROR:errors.weCantSubscribeToSelectedAddress'
  );

  //TODO: изменить текст в StyledErrorSubText. Сейчас временно убрали
  return (
    <StyledBox>
      <ScoreErrorIcon />
      <StyledText>{isObjectDataError ? objectErrorText : errorText}</StyledText>
      {/* {isObjectDataError && (
        <StyledErrorSubText>{objectErrorSubText}</StyledErrorSubText>
      )} */}
      <StyledButtonBox isObjectDataError={isObjectDataError}>
        <Button onClick={handleClick} variant="primary">
          {buttonLabel}
        </Button>
      </StyledButtonBox>
    </StyledBox>
  );
};
