import { styled, typography } from '@pulse-web-ui/theme';

export const InsurancePriceCardContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  border: 2px solid ${({ theme }) => theme.colors.surface.brand};
  padding: 16px 24px;
  color: ${({ theme }) => theme.colors.text.primary};
  border-radius: ${({ theme }) => theme.common.radius};
`;

export const InsurancePriceCardTitle = styled.div`
  ${typography.regular16};
  margin-bottom: 2px;
`;

export const InsurancePriceCardPremiumAndDelta = styled.div`
  ${typography.medium20};
`;
