/* eslint-disable indent */
import { media, mixins, styled, typography } from '@pulse-web-ui/theme';

import type { SumPerMonthWrapperProps } from './sum-per-month.types';

export const SumPerMonthWrapper = styled.div<SumPerMonthWrapperProps>`
  ${mixins.flex({ horizontal: false, justifyContent: 'flex-start' })}
  color: ${({ theme }) => theme.colors.text.primary};
  padding: 16px 24px 12px;
  border-radius: ${({ theme }) => theme.common.radius};
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
  min-height: 81px;
  box-sizing: border-box;
  width: 100%;
  justify-content: flex-start;
  border: 2px solid
    ${({ theme, isError }) =>
      isError ? theme.colors.accent.error.normal : theme.colors.surface.brand};

  ${media.desktop} {
    min-height: 114px;
    height: 100%;
  }
`;

export const SumPerMonthValue = styled.div`
  ${typography.bold24}
  white-space: nowrap;
  margin-bottom: 2px;
`;

export const SumPerMonthTitle = styled.div`
  ${typography.regular16}
  margin-bottom: 2px;
`;

export const StyledSpinnerWrapper = styled.div`
  margin-top: 6px;
`;

export const SumPerMonthErrorMessage = styled.div`
  ${typography.regular14}
  letter-spacing: 0.02em;
  color: ${({ theme }) => theme.colors.text.error};
  padding-top: 16px;

  ${media.desktop} {
    padding-top: 13px;
  }
`;

export const SumPerMonthDesc = styled.div`
  ${typography.regular16}
  line-height: 24px;
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const SumPerMonthPromoWrapper = styled.div`
  margin-bottom: 2px;
`;

export const SumPerMonthBaseValue = styled.span`
  ${typography.regular16}
  line-height: 24px;
  text-decoration: line-through;
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-left: 8px;
`;

export const SumPerMonthPromoValue = styled.span`
  ${typography.medium24}
  color: ${({ theme }) => theme.colors.text.interactive};
`;

export const SumPerMonthPromoDesc = styled.span`
  ${typography.medium24}
  color: ${({ theme }) => theme.colors.text.primary};
`;
