/* eslint-disable @typescript-eslint/no-empty-function */

/* eslint-disable indent */
import { useCallback, useContext, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@pulse-web-ui/button';
import { DaDataFio, DaDataSuggestion } from '@pulse-web-ui/dadata';
import { Datepicker } from '@pulse-web-ui/datepicker';
import { HelperText } from '@pulse-web-ui/helper-text';
import { Add } from '@pulse-web-ui/icons';
import { useTheme } from '@pulse-web-ui/theme';

import { FilterParts, StyledDadataFioSuggestions } from '@src/components';
import { AdaptiveColumns, CalendarContainer } from '@src/components/container';
import { useHandlePressKey } from '@src/hooks';
import { useNsDetailsInsured } from '@src/pages/ns-form/hooks';
import { Store, WizardActionTypes } from '@src/store';
import { InsurancePersonsForm, KeyCode } from '@src/types';
import { addTestAttribute, calculateAge } from '@src/utils';

import {
  InsurancePersonFormButton,
  InsurancePersonFormContainer,
  InsurancePersonFormHead,
  InsurancePersonFormNumber,
  InsurancePersonFormSubTitle,
  InsurancePersonFormTitle,
  InsurancePersonFormTitleBox,
  InsurancePersonGroup,
  StyledButton,
  StyledDeleteButton,
  StyledVisibleContainer,
} from './insurance-person-form.styles';
import { InsurancePersonFormProps } from './insurance-person-form.types';
import {
  getDatepickerPeriod,
  getInsurancePersonSubTitle,
  getInsurancePersonTitle,
  isVisibleIsMeButton,
} from './utils';

export const InsurancePersonForm = ({
  personsIndex,
  selectedIndex,
  isLastPerson,
  ageMin,
  handleSelectionMe,
  pressEnterNextStep,
  effectiveSince,
  lockedFields,
  fields,
  showFormTitle,
  showAddButton,
  showDeleteButton,
  handleAddField,
  handleDeleteField = () => {},
}: InsurancePersonFormProps) => {
  const { dispatch } = useContext(Store);
  const { t } = useTranslation();
  const [isFocusedMiddleName, setIsFocusedMiddleName] = useState(false);
  const theme: any = useTheme();

  const getDadataValue = (value: string | undefined) =>
    ({ value: value || '' } as DaDataSuggestion<DaDataFio>);

  const {
    formState: { errors },
    control,
    getValues,
    setValue,
  } = useFormContext<InsurancePersonsForm>();
  const personErrors = errors.persons?.[personsIndex];

  const { profileDataForForm } = useNsDetailsInsured();

  const isMe = getValues(`persons.${personsIndex}.isMe`);
  const userAge = profileDataForForm?.birthDate
    ? calculateAge(new Date(profileDataForForm.birthDate))
    : null;
  const isMeVisible = !ageMin
    ? true
    : userAge
    ? isVisibleIsMeButton(ageMin, userAge)
    : ageMin !== 1;

  const showButton =
    (isMeVisible && selectedIndex === -1) || selectedIndex === personsIndex;

  const AddButton = useCallback(() => {
    const lastElement = fields?.length - 1;

    if (lastElement === personsIndex) {
      return (
        <StyledButton>
          <Button
            variant="text"
            icon={<Add width={24} color={theme.colors.text.interactive} />}
            onClick={handleAddField}
            {...addTestAttribute(
              `insurancePerson.${personsIndex}.button.addMore`
            )}
          >
            {t('COMMON:buttons.addMore')}
          </Button>
        </StyledButton>
      );
    }

    return null;
  }, [fields?.length, personsIndex]);

  const handleOnFocusMiddleName = () => {
    if (isMe) {
      setIsFocusedMiddleName(!isFocusedMiddleName);
    }
  };

  // TODO: Дописать логику для переключения по инпутам.
  const handleKeyPressEnter = () => {
    pressEnterNextStep &&
      dispatch({
        type: WizardActionTypes.UpdateWantNextStep,
        payload: true,
      });
  };
  useHandlePressKey(KeyCode.ENTER, handleKeyPressEnter);

  return (
    <InsurancePersonFormContainer data-fieldindex={personsIndex}>
      <InsurancePersonGroup>
        <InsurancePersonFormHead>
          <InsurancePersonFormTitleBox>
            <InsurancePersonFormNumber>
              {personsIndex + 1}
            </InsurancePersonFormNumber>
            {showFormTitle && ageMin && (
              <>
                <InsurancePersonFormTitle>
                  {getInsurancePersonTitle(ageMin)}
                </InsurancePersonFormTitle>
                <InsurancePersonFormSubTitle>
                  {getInsurancePersonSubTitle(ageMin)}
                </InsurancePersonFormSubTitle>
              </>
            )}
          </InsurancePersonFormTitleBox>
          <StyledVisibleContainer>
            {showDeleteButton && (
              <StyledDeleteButton
                onClick={() => handleDeleteField(personsIndex)}
                {...addTestAttribute(
                  `insurancePerson.${personsIndex}.button.delete`
                )}
              >
                {t('COMMON:buttons.delete')}
              </StyledDeleteButton>
            )}
            {showButton && (
              <Controller
                control={control}
                name={`persons.${personsIndex}.isMe`}
                render={({ field: { onChange, value } }) => (
                  <InsurancePersonFormButton
                    active={value}
                    onClick={() => {
                      onChange(!value);
                      handleSelectionMe(personsIndex);
                    }}
                    {...addTestAttribute(
                      `insurancePerson.${personsIndex}.button.isMe`
                    )}
                  >
                    {t('COMMON:buttons.isMe')}
                  </InsurancePersonFormButton>
                )}
              />
            )}
          </StyledVisibleContainer>
        </InsurancePersonFormHead>
        <div>
          <AdaptiveColumns marginBottom={0}>
            <Controller
              control={control}
              name={`persons.${personsIndex}.lastName`}
              render={({ field, fieldState }) => (
                <HelperText
                  status={fieldState.error ? 'error' : 'default'}
                  message={
                    fieldState.error?.message && t(fieldState.error.message)
                  }
                  testId={`insurancePerson.${personsIndex}.lastName.error`}
                >
                  <StyledDadataFioSuggestions
                    filterParts={[FilterParts.SURNAME]}
                    onChange={(data) =>
                      setValue(field.name, data?.value || field.value)
                    }
                    defaultQuery={field.value}
                    isPossibleOverflow={isLastPerson}
                    uid={`dadata.${personsIndex}.lastName`}
                    inputProps={{
                      ...field,
                      label: t('COMMON:labels.lastName') || '',
                      disabled: isMe && lockedFields?.lastName,
                      error: !!fieldState.error?.message,
                      ...addTestAttribute(
                        `insurancePerson.${personsIndex}.lastName`
                      ),
                    }}
                    value={getDadataValue(field.value)}
                  />
                </HelperText>
              )}
            />

            <Controller
              control={control}
              name={`persons.${personsIndex}.firstName`}
              render={({ field, fieldState }) => (
                <HelperText
                  status={fieldState.error ? 'error' : 'default'}
                  message={
                    fieldState.error?.message && t(fieldState.error.message)
                  }
                  testId={`insurancePerson.${personsIndex}.firstName.error`}
                >
                  <StyledDadataFioSuggestions
                    filterParts={[FilterParts.NAME]}
                    onChange={(data) =>
                      setValue(field.name, data?.value || field.value)
                    }
                    isPossibleOverflow={isLastPerson}
                    defaultQuery={field.value}
                    uid={`dadata.${personsIndex}.firstName`}
                    inputProps={{
                      ...field,
                      label: t('COMMON:labels.firstName') || '',
                      disabled: isMe && lockedFields?.firstName,
                      error: !!fieldState.error?.message,
                      ...addTestAttribute(
                        `insurancePerson.${personsIndex}.firstName`
                      ),
                    }}
                    value={getDadataValue(field.value)}
                  />
                </HelperText>
              )}
            />

            <Controller
              control={control}
              name={`persons.${personsIndex}.middleName`}
              render={({ field, fieldState }) => (
                <HelperText
                  status={fieldState.error ? 'error' : 'default'}
                  message={
                    fieldState.error?.message && t(fieldState.error.message)
                  }
                  testId={`insurancePerson.${personsIndex}.middleName.error`}
                >
                  <StyledDadataFioSuggestions
                    filterParts={[FilterParts.PATRONYMIC]}
                    onChange={(data) => {
                      setValue(field.name, data?.value || field.value);
                    }}
                    defaultQuery={field.value}
                    uid={`dadata.${personsIndex}.middleName`}
                    isPossibleOverflow={isLastPerson}
                    inputProps={{
                      ...field,
                      label:
                        (isMe && !field?.value && !isFocusedMiddleName
                          ? t('COMMON:labels.noPatronymic')
                          : t('COMMON:labels.middleName')) || '',
                      disabled: isMe && lockedFields?.middleName,
                      error: !!fieldState.error?.message,
                      onFocus: handleOnFocusMiddleName,
                      onBlur: () => {
                        field.onBlur();
                        handleOnFocusMiddleName();
                      },
                      ...addTestAttribute(
                        `insurancePerson.${personsIndex}.middleName`
                      ),
                    }}
                    value={getDadataValue(field.value)}
                  />
                </HelperText>
              )}
            />

            <HelperText
              status={
                (personErrors?.birthDate?.message && 'error') || undefined
              }
              message={
                personErrors?.birthDate?.message &&
                t(personErrors.birthDate.message)
              }
              testId={`insurancePerson.${personsIndex}.birthDate.error`}
            >
              <Controller
                control={control}
                name={`persons.${personsIndex}.birthDate`}
                render={({ field: { onChange, value } }) => (
                  <Datepicker
                    selected={value ? new Date(value) : undefined}
                    onChange={onChange}
                    showMonthDropdown
                    showYearDropdown
                    disabled={
                      isMe &&
                      lockedFields?.birthDate &&
                      !!profileDataForForm.birthDate
                    }
                    label={t('COMMON:labels.dateOfBirth') || ''}
                    error={!!personErrors?.birthDate?.message}
                    popperContainer={CalendarContainer}
                    {...getDatepickerPeriod(effectiveSince, ageMin)}
                    {...addTestAttribute(
                      `insurancePerson.${personsIndex}.birthDate`
                    )}
                  />
                )}
              />
            </HelperText>
          </AdaptiveColumns>
        </div>
      </InsurancePersonGroup>
      {showAddButton && <AddButton />}
    </InsurancePersonFormContainer>
  );
};
