import { media, styled, typography } from '@pulse-web-ui/theme';

import { FormSub } from '@src/components';

export const PageTitle = styled.h1`
  ${typography.medium24};
  color: ${({ theme }) => theme.colors.text.primary};
  margin-bottom: 8px;
`;

export const PageSubTitle = styled.h3<{ marginBottom?: number }>`
  ${typography.regular16};
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${marginBottom}px` : '24px'};
`;

export const PageSubTitleRegular18 = styled(PageSubTitle)`
  ${typography.subhead2.regular};

  ${media.desktop} {
    line-height: 26px;
  }
`;

export const StyledDownload = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 42px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.surface.brand};

  ${typography.regular18};

  p {
    margin-left: 10px;
  }
`;

export const StyledFormSub = styled(FormSub)`
  display: flex;
  align-items: center;

  text-align: center;
`;

export const SectionTitle = styled.h4`
  ${typography.medium18};
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-bottom: 16px;
`;

export const BoldText = styled.span`
  font-weight: 600;
`;

export const PreLineText = styled.span`
  ${media.desktop} {
    white-space: pre-line;
  }
`;
