/* eslint-disable indent */
import type { FC } from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import type { IconProps } from '@pulse-web-ui/icons';
import { Close } from '@pulse-web-ui/icons';
import { Spinner } from '@pulse-web-ui/spinner';
import { useTheme } from '@pulse-web-ui/theme';

import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import { analyticEvents, iflRoute, nsRoute } from '@src/constants';
import { useDownloadPdf } from '@src/hooks';
import { Store } from '@src/store';
import type { DocumentType } from '@src/types';
import { openPdf } from '@src/utils';

import { DownloadItemContent, DownloadItemWrapper } from './components.styles';

export const ClientDocumentItem: FC<DocumentType & { icon: FC<IconProps> }> = (
  documentItem
) => {
  const {
    state: {
      stateAuth: { authTokens, authorizeRefRoute },
    },
  } = useContext(Store);
  const theme: any = useTheme();
  const { t } = useTranslation();

  const OrderDocumentLabel = {
    Memo: t('ORDER:labels.memoDocument'),
    Rules: t('ORDER:labels.rulesDocument'),
    Contract: t('ORDER:labels.contractDocument'),
    KeyInfoDocument: t('ORDER:labels.keyInfoDocument'),
  };

  const DocumentIcon = documentItem.icon;

  const { isLoading, res, refetch, error } = useDownloadPdf(
    `kei-file-${documentItem.clientDocument}`,
    documentItem.clientDocument,
    authTokens?.authorization.accessToken || '',
    []
  );

  const onDocumentLoadHandler = () => {
    const params = {
      document_type: documentItem.clientDocumentType,
    };

    switch (authorizeRefRoute) {
      case iflRoute:
        sendAnalyticEvent(analyticEvents.viewDocumentRealty, params);
        break;
      case nsRoute:
        sendAnalyticEvent(analyticEvents.viewDocumentAccident, params);
        break;
    }

    if (!isLoading && res) {
      openPdfHandle();
    } else {
      refetch().then((res) => {
        openPdf(res?.data?.data as unknown as string, 'doc.pdf');
      });
    }
  };

  const openPdfHandle = () => {
    openPdf(res, 'doc.pdf');
  };

  return (
    <DownloadItemWrapper>
      <DownloadItemContent onClick={onDocumentLoadHandler}>
        <DocumentIcon
          color={theme.colors.icon?.quaternary || theme.colors.text.tertiary}
        />
        <div>{OrderDocumentLabel[documentItem.clientDocumentType]}</div>
      </DownloadItemContent>
      {isLoading ? <Spinner /> : null}
      {error ? <Close color="red" /> : null}
    </DownloadItemWrapper>
  );
};
