import { useContext, useMemo } from 'react';

import { ClientCategory, iflFlatRoute, iflHouseRoute } from '@src/constants';
import { Store } from '@src/store';

export const useIsVipIFL = (): boolean => {
  const {
    state: {
      stateAuth: { authorizeRefRoute, authTokens },
      stateUser: { profile },
    },
  } = useContext(Store);

  const isVip = useMemo(() => {
    return (
      authTokens?.isVip ||
      profile?.profile?.clientCategory === ClientCategory.VIP
    );
  }, [authTokens?.isVip, profile?.profile?.clientCategory]);

  return useMemo(
    () =>
      (authTokens?.authorization &&
        isVip &&
        (authorizeRefRoute === iflHouseRoute ||
          authorizeRefRoute === iflFlatRoute)) ||
      false,
    [authorizeRefRoute, isVip]
  );
};
