import { Button } from '@pulse-web-ui/button';
import { media, mixins, styled, typography } from '@pulse-web-ui/theme';

export const StyledButton = styled(Button)`
  ${media.desktop} {
    width: auto;
  }
  width: 100%;
  box-sizing: border-box;
`;

export const PageBackButton = styled(Button)<{
  mitePolicy?: boolean;
}>`
  ${typography.regular16}
  ${mixins.flex({
    inline: true,
    verticalAlignment: 'center',
    horizontalAlignment: 'flex-start',
  })};
  margin-bottom: 10px;
  position: relative;
  z-index: 1;
`;

export const PromoSubmitButton = styled(Button)`
  ${typography.bold18};
  ${media.desktop} {
    width: auto;
  }
  width: 100%;
`;
